import { Col, Row, Card } from "antd";

export const SiteOptions = () => {
  const { Meta } = Card;
  const cardData = [
    {
      img: "https://instock.com/en/images/system-components/incloud_icon_hu7cabe7d98d68201b9422a8eefe4c4860_51094_300x0_resize_q75_h2_box_3.webp",
      description:
        " is a cloud software orchestrating all fulfillment-related operations in the Instock ASRS.",
      linkName: "Go Incloud",
      link: "",
    },
    {
      img: "https://instock.com/en/images/system-components/gridcell_icon_hu7cabe7d98d68201b9422a8eefe4c4860_202906_300x0_resize_q75_h2_box_3.webp",
      description:
        "Grid  is a fully static structure that resembles commercial racking. It is the framework of the Instock ASRS",
      linkName: "Explore our Grid",
      link: "",
    },
    {
      img: "https://instock.com/en/images/system-components/bin_icon_hu7cabe7d98d68201b9422a8eefe4c4860_480700_300x0_resize_q75_h2_box_3.webp",
      description:
        "Bins are containers that hold inventory. They are moved inside the grid by robots and can be stacked.",

      linkName: "Bin Specifications",
      link: "",
    },
    {
      img: "https://instock.com/en/images/system-components/robot_icon_hu7cabe7d98d68201b9422a8eefe4c4860_547972_300x0_resize_q75_h2_box_3.webp",
      description:
        " are the only moving element of the system and are autonomous and mobile in three dimensions.      ",
      linkName: "See our Robots",
      link: "",
    },
  ];

  const listCardData = cardData.map((data) => {
    return (
      <Col xs={24} sm={24} md={6}>
        <Card hoverable style={{ height: "100%" }}>
          <img width={"150px"} alt="example" src={data.img} />
          <br></br>
          {data.description}
          <br></br>
          <br></br>
          <Meta description={data.linkName} />
        </Card>
      </Col>
    );
  });
  return (
    <div
      style={{
        backgroundColor: "#3597DE",
        borderRadius: "1.5rem",
        borderStyle: "hidden",
        padding: "25px",
      }}
    >
      <Row
        gutter={[18, 18]}
        style={{
          borderRadius: "1.5rem",
          borderStyle: "hidden",
          maxWidth: "1100px",
          width: "100%",
        }}
      >
        {listCardData}
      </Row>
    </div>
  );
};
