import { Col, Row } from "antd";

export const FooterOptionTwo = () => {
  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        height: "100%",
      }}
    >
      <Row
        gutter={[18, 18]}
        style={{
          maxWidth: "1200px",
          margin: "5px",
          width: "100%",
          backgroundColor: "#151A21",
          padding: "50px",
          borderTopLeftRadius: "1.5rem",
          borderTopRightRadius: "1.5rem",
          borderStyle: "hidden",
          color: "white",
          height: "100%",
        }}
      >
        <Col xs={12} sm={12} md={6}>
          <h3 className="header">System</h3>
          <br></br>
          <ul className="footer">
            <li>Incloud</li>
            <li>Grid</li>
            <li>Bins</li>
            <li>Robots</li>
          </ul>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <h3 className="header">Benefits</h3>
          <br></br>
          <ul className="footer">
            <li>Incloud</li>
            <li>Grid</li>
            <li>Bins</li>
            <li>Robots</li>
          </ul>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <h3 className="header">Resources</h3>
          <br></br>
          <ul className="footer">
            <li>Documentation</li>
            <li>API References</li>
          </ul>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <h3 className="header">About</h3>
          <br></br>
          <ul className="footer">
            <li>Company</li>
            <li>News</li>
            <li>Press Kit</li>
            <li>Careers</li>
          </ul>
        </Col>
        <Col span={24}>
          <span
            style={{ display: "inline-block", fontFamily: "sundayAfternoon" }}
          >
            {" "}
            {"Fintralogistics"}
          </span>

          <span style={{ display: "inline-block", float: "right" }}>
            <p>Privacy Policy</p>
          </span>
        </Col>
      </Row>
    </div>
  );
};
