import { Row } from "antd";
import { ViralNationPageLayout } from "../components/layouts/viralNationLayout/viralNationPageLayout";
import { Hero } from "../viralNation/hero";
import { Products } from "../viralNation/products";

export const ViralNation = () => {
  return (
    <ViralNationPageLayout>
      <div
        style={{
          background: "black",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Row>
          <Hero />
          <Products />
        </Row>
      </div>
    </ViralNationPageLayout>
  );
};
