import { Button, Card, Col, Row } from "antd";
import finLogo from "../images/template-hero.png";
export const ComingSoon = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Row
        gutter={[18, 18]}
        style={{ textAlign: "center", margin: "4px", maxWidth: "500px" }}
      >
        <Col span={24}>
          <img
            alt="logo"
            src={finLogo}
            style={{ width: "100%", maxWidth: "500px" }}
          />
        </Col>

        <Col style={{ textAlign: "center" }}>
          <Card>
            <p>
              {
                "Create Hub simplifies landing page creation with customizable templates and an intuitive interface. Craft captivating pages optimized for conversions effortlessly. Say goodbye to complex coding and design struggles – focus on engaging your audience and achieving your goals. Join marketers worldwide in elevating your online presence with Create Hub. Experience professional-grade landing pages without the hassle. Start your journey to online excellence today!"
              }
            </p>
          </Card>
        </Col>

        <Col
          span={24}
          style={{ textAlign: "center", fontFamily: "sundayAfternoon" }}
        >
          <Button
            href="https://www.linkedin.com/"
            rel="noreferrer"
            target="_blank"
            style={{
              color: "#E99923",
              fontFamily: "sundayAfternoon",
            }}
          >
            {" "}
            Linkedin
          </Button>
          <Button
            href="https://www.instagram.com/"
            rel="noreferrer"
            target="_blank"
            style={{
              color: "#E99923",
              marginLeft: "4px",
              fontFamily: "sundayAfternoon",
            }}
          >
            {"Instagram"}
          </Button>
          <Button
            href="https://www.tiktok.com/"
            rel="noreferrer"
            target="_blank"
            style={{
              color: "#E99923",
              marginLeft: "4px",
              fontFamily: "sundayAfternoon",
            }}
          >
            {"TikTok"}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
