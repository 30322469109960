import { Avatar, Card, Col, Row } from "antd";

import { Link } from "react-router-dom";

export const Benefits = () => {
  const { Meta } = Card;
  const benefits = [
    {
      img: "https://instock.com/en/images/benefits-icons/modular-and-elastic.svg",
      title: "Modular & Elastic",
      description:
        "Maximize utility of every available cubic foot. Even 1,000 square feet can be turned into micro-fulfillment using our lego-like grid modules",
      link: "",
    },
    {
      img: "https://instock.com/en/images/benefits-icons/reliable.svg",
      title: "Reliable",
      description:
        "All robots are identical and every robot can perform every task. No-SPOF principle enables high uptime of system as a whole.",
      link: "",
    },
    {
      img: "https://instock.com//en/images/benefits-icons/simulation-first.svg",
      title: "Simulation-first",
      description:
        "Integrate with our sandbox and simulate your future operations free, before any commitment!",
      link: "",
    },
    {
      img: "https://instock.com/en/images/benefits-icons/reliable.svg",
      title: "As-a-service",
      description:
        "With zero CAPEX, your eCommerce is de-risked. Low system relocation fees make it also resilient and future-proof.",
      link: "",
    },
  ];
  const listCardBenefitData = benefits.map((data) => {
    return (
      <Col xs={24} sm={24} md={12}>
        <Card hoverable>
          <Meta
            avatar={<Avatar shape="square" src={data.img} />}
            title={data.title}
            description={
              <div>
                {data.description}
                <p>
                  {" "}
                  <Link to={data.link}>{"More"}</Link>
                </p>
              </div>
            }
          />
        </Card>
      </Col>
    );
  });
  return (
    <Row
      gutter={[18, 18]}
      style={{
        maxWidth: "1200px",
        marginLeft: "3rem",
        paddingTop: "3rem",
        paddingBottom: "3rem",
        marginBottom: "40px",
      }}
    >
      <Col span={24}>
        <h3>{"Benefits"}</h3>
        <h4>{"Robotics-as-a-Service, at your service"}</h4>
        <p>
          {
            "Instock RaaS delivers flexible and reliable fulfillment automation wherever you need it."
          }
        </p>
      </Col>
      {listCardBenefitData}
    </Row>
  );
};
