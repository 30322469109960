import { Button, Col } from "antd";
import { HeroVideo } from "../components/landingPage/heroVideo";
import { Benefits } from "../components/landingPage/benefits";
import { SiteOptions } from "../components/landingPage/siteOptions";
import { Company } from "../components/landingPage/company";
import { PageLayout } from "../components/layouts/pageLayout";

export const LandingPage = () => {
  return (
    <PageLayout>
      <HeroVideo />
      <Col
        xs={24}
        sm={24}
        md={24}
        style={{
          marginLeft: "3rem",
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <h2>
          <b>Modern fulfillment automation</b>
        </h2>
        <p>
          Goods-to-person system maximizing savings, storage density and
          throughput for eCommerce operators.
        </p>
        <Button>{"Explore Instock system"}</Button>
      </Col>

      <SiteOptions />
      <Benefits />
      <Company />
    </PageLayout>
  );
};
