import { Col, Row, Button } from "antd";

export const Hero = () => {
  return (
    <div
      style={{
        background: "black",
        height: "50vh",
        backgroundImage:
          "url(https://dontbeshy.com/wp-content/themes/dbs2023/images/texture/stout-texture-992.webp)",
        backgroundSize: "500px",
      }}
    >
      <Row gutter={[18, 18]} style={{ maxWidth: "1400px" }}>
        <Col xs={24} sm={24} md={24} style={{ color: "white" }}>
          <div
            style={{
              margin: "50px",
            }}
          >
            <h1>B2B MARKETING</h1>
            <h1>You're right.</h1>
            <h1>It can be better.</h1>
            <Button>ENERGY, WELL SPENT</Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
