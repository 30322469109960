import React from "react";
import { ViralNationFooter } from "./viralNationFooter";
import { ViralNationNavBar } from "./viralNationNavBar";

export const ViralNationPageLayout = ({ children }) => {
  return (
    <div>
      <ViralNationNavBar />
      <div>{children}</div>
      <ViralNationFooter />
    </div>
  );
};
