import { Avatar, Col, Row } from "antd";
import { Link } from "react-router-dom";

export const NotFound = () => {
  function makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  console.log(makeid(6));

  return (
    <Row>
      <Col span={24} style={{ textAlign: "center" }}>
        <Avatar src={`https://robohash.org/${makeid(6)}`} size={350}></Avatar>

        <h1>Oooops!</h1>
        <h2>We can't seem to find the page you're looking for.</h2>
        <strong>
          <p>{"Error code: 404"}</p>
        </strong>
        <Link to="/">Home</Link>
      </Col>
    </Row>
  );
};
