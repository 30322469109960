import { Button, Col, Row } from "antd";

export const Company = () => {
  return (
    <div
      style={{
        borderRadius: "1.5rem",
        borderStyle: "hidden",
        // backgroundColor: "#50d7e8",
        backgroundColor: "#3597DE",
        marginBottom: "40px",
      }}
    >
      <Row
        gutter={[18, 18]}
        style={{
          maxWidth: "1200px",
          // marginLeft: "3rem",
          // paddingTop: "3rem",
          // paddingBottom: "3rem",
        }}
      >
        <Col xs={24} sm={24} md={12}>
          <div style={{ margin: "50px" }}>
            <h3>{"Company"}</h3>
            <h2 style={{ fontSize: "3rem" }}>
              {"Built from scratch for real-world operators"}
            </h2>
            <p>
              {
                "Our mission is to create universal way for autonomus mobile robots to store and move inventory in long-life containers. Instock’s breakthrough patented technology brings to eCommerce operators radically improved flexibility, modularity, and price point."
              }
            </p>
            <Button>About Us</Button>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <img
            style={{
              borderTopRightRadius: "1.5rem",
              borderStyle: "hidden",
            }}
            width={"100%"}
            alt="warehouse racks and forklift"
            src="https://instock.com/en/images/about-us_huab67fc44c05858831e554cd29076a3b6_5184740_1500x0_resize_q75_h2_box_2.webp"
          />
        </Col>
      </Row>
    </div>
  );
};
