import { Col, Row } from "antd";

export const Products = () => {
  return (
    <div
      style={{
        background: "black",
        justifyContent: "center",
        display: "flex",
        backgroundColor: "white",
        width: "100%",
      }}
    >
      <Row gutter={[18, 18]} style={{ top: "-50px" }}>
        <Col xs={24} sm={24} md={8}>
          <div>
            <h1>Products</h1>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <div>
            <h1>Products</h1>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <div>
            <h1>Products</h1>
          </div>
        </Col>
      </Row>
    </div>
  );
};
