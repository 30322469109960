import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import { NavBar } from "./components/navigation/navBar";
import { LandingPage } from "./pages/LandingPage";
import ScrollToTop from "./utils/ScrollToTop";
import { NotFound } from "./utils/notFound";

import { ComingSoon } from "./pages/ComingSoon";

import { Assets } from "./pages/Assets";
import { ViralNation } from "./pages/ViralNation";
import { SocialShephard } from "./pages/SocialShephard";
import { DontBeShy } from "./pages/DontBeShy";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<ComingSoon />} />
        <Route exact path="/site" element={<LandingPage />} />
        <Route exact path="/viral-nation" element={<ViralNation />} />
        <Route exact path="/social-shephard" element={<SocialShephard />} />
        <Route exact path="/dont-be-shy" element={<DontBeShy />} />
        {/* Utils links */}
        <Route exact path="/assets" element={<Assets />} />

        <Route exact path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
