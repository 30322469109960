import React from "react";

import { DontBeShyNavBar } from "./dontBeShyNavBar";
import { DontBeShyFooter } from "./dontBeShyFooter";

export const DontBeShyPageLayout = ({ children }) => {
  return (
    <div>
      <DontBeShyNavBar />
      <div>{children}</div>
      <DontBeShyFooter />
    </div>
  );
};
