import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

// import tfgLogoImage from "../../images/tfg-logo-01.png";
import { Link } from "react-router-dom";
import { ViralNationMainMenu } from "./viralNationMainMenu";

export const ViralNationNavBar = () => {
  return (
    <Navbar style={{ background: "black" }} sticky="top">
      <Container>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Navbar.Brand>
            <img
              src={
                "https://www.viralnation.com/wp-content/uploads/2023/05/VN_Logo_White.svg"
              }
              className="d-inline-block align-top"
              // width="30"
              height="30"
              alt="logo"
            />
            {/* <small>{"POWERED BY "}</small> */}
            {/* <img alt="techForGood logo" src={tfgLogoImage} height={"50rem"} /> */}
          </Navbar.Brand>
        </Link>

        <Nav className="justify-content-end">
          <ViralNationMainMenu />
        </Nav>
      </Container>
    </Navbar>
  );
};
