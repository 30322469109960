import React from "react";

import { NavBar } from "../navigation/navBar";
import { FooterOptionTwo } from "../navigation/footerOptionTwo";
import { Row } from "antd";

export const PageLayout = ({ children }) => {
  return (
    <div>
      <NavBar />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Row
          style={{
            maxWidth: "1200px",
            margin: "8px",
            width: "100%",
          }}
        >
          {children}
        </Row>
      </div>

      <FooterOptionTwo />
    </div>
  );
};
