import { Col, Row } from "antd";
import { SocialShephardPageLayout } from "../components/layouts/socialShephardLayout/socialShephardPageLayout";

export const SocialShephard = () => {
  return (
    <SocialShephardPageLayout>
      <Row gutter={[18, 18]}>
        <Col span={24}>
          <h1>SocialShephard</h1>
        </Col>
      </Row>
    </SocialShephardPageLayout>
  );
};
