import { Button, Col } from "antd";

export const HeroVideo = () => {
  return (
    <Col
      span={24}
      style={{
        marginTop: "10px",
        position: "relative",
        display: "flex",
        maxWidth: "1200px",
        justifyContent: "center",
      }}
    >
      <video
        autoPlay
        loop
        muted
        style={{
          borderRadius: "1.5rem",
          borderStyle: "hidden",
          width: "95%",
          objectFit: "cover",
        }}
      >
        <source src="https://instock.com/en/videos/hero.mp4" type="video/mp4" />
      </video>
      <div
        style={{
          position: "absolute",
          zIndex: 10,
          left: "4.5rem",
          bottom: "4rem",
        }}
      >
        <h2 style={{ color: "white" }}>
          <b>{"GET IN TOUCH"}</b>
        </h2>
        <Button
          style={{
            backgroundColor: "#3597DE",
            color: "white",
            fontSize: "1rem",
            height: "100%",
          }}
        >
          Book a consultation
        </Button>
      </div>
    </Col>
  );
};
