import React from "react";
import { SocialShephardFooter } from "./socialShephardFooter";
import { SocialShephardNavBar } from "./socialShephardNavBar";

export const SocialShephardPageLayout = ({ children }) => {
  return (
    <div>
      <SocialShephardNavBar />
      <div>{children}</div>
      <SocialShephardFooter />
    </div>
  );
};
