import { Row } from "antd";

import { DontBeShyPageLayout } from "../components/layouts/dontBeShy/dontBeShyPageLayout";
import { Hero } from "../dontBeShy/hero";

export const DontBeShy = () => {
  return (
    <DontBeShyPageLayout>
      <div
        style={{
          background: "black",
          justifyContent: "center",
        }}
      >
        <Row>
          <Hero />
        </Row>
      </div>
    </DontBeShyPageLayout>
  );
};
