import { Button, Col, Row } from "antd";

export const Hero = () => {
  return (
    <div
      style={{ background: "black", justifyContent: "center", display: "flex" }}
    >
      <Row gutter={[18, 18]} style={{ maxWidth: "1400px" }}>
        <Col xs={24} sm={24} md={12} style={{ color: "white" }}>
          <div style={{ margin: "50px" }}>
            <h1>Social-First Marketing at Scale</h1>
            <p>
              {
                "Viral Nation is the global leader in social-first transformation and commerce."
              }
            </p>
            <Button>Let's Connect</Button>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <div style={{ margin: "50px" }}>
            <img
              width={"75%"}
              src="https://www.viralnation.com/wp-content/uploads/2024/04/VN-Web-Header.webp"
              alt="phone screenshots"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
