import { Button, Card, Col, Image, Row, Typography } from "antd";
import { PageLayout } from "../components/layouts/pageLayout";

export const Assets = () => {
  const { Paragraph } = Typography;

  const assetData = [
    {
      url: "https://create-hub-customers.s3.amazonaws.com/fintralogistics/og-image-fin.png",
      size: "1200x630px",
      type: ".png",
      title: "OG Image",
    },
    {
      url: "https://create-hub-customers.s3.amazonaws.com/fintralogistics/fin-logo-04-01.webp",
      size: "1200x630px",
      type: ".webp",
      title: "og-image-no-background",
    },
  ];

  const listAssetData = assetData.map((data) => {
    return (
      <Col xs={24} sm={24} md={6}>
        <div style={{ textAlign: "center" }}>
          <Card
            title={data.title}
            cover={<Image src={data.url} />}
            style={{ padding: "10px" }}
            actions={[
              data.size,
              data.type,
              <Paragraph
                copyable={{
                  text: data.url,
                }}
              ></Paragraph>,
            ]}
          >
            <Button>DOWNLOAD</Button>
          </Card>
        </div>
      </Col>
    );
  });

  return (
    <PageLayout>
      <Row gutter={[18, 18]}>
        <Col span={24}>
          <h1>Digital Assets</h1>
          <p>
            {/* {"For feedback "} */}
            {/* <a href="mailto:hello@techforgood.lk">{"email us"}</a> */}
            {" Illustrator size (.ai) "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://create-hub-customers.s3.amazonaws.com/fintralogistics/logo-fintralogistics.ai"
            >
              download
            </a>
          </p>
          <p>
            Font File - "Sunday Afternoon" "
            <a
              target="_blank"
              rel="noreferrer"
              href="https://create-hub-customers.s3.amazonaws.com/fintralogistics/fonts/sunday-afternoon/Sunday-Afternoon/Sunday+Afternoon.otf"
            >
              download
            </a>
          </p>
        </Col>
        {listAssetData}
      </Row>
    </PageLayout>
  );
};
